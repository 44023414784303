<template>
	<svg
		id="b1cf8e01-ecef-48bb-a26b-dd36e04e5d88"
		data-name="Layer 1"
		xmlns="http://www.w3.org/2000/svg"
		width="1155.6"
		height="697.61"
		viewBox="0 0 1155.6 697.61"
	>
		<title>video call</title>
		<path
			d="M1165.61,751.83a189.09,189.09,0,0,1-16.41,31.52,136.38,136.38,0,0,1-9.94,13.66l-.11.13H27.8L28,797c1-.78,6.87-5.53,16-13.66,6.55-5.84,14.77-13.42,24-22.54,75.49-74.25,220.5-250,99.73-411-112.27-149.67,8.61-176.84,88.35-180a228.25,228.25,0,0,1,89.87,14.69c36.83,14,104.16,35.47,191.88,45.3q4.32.49,8.72.94a840.2,840.2,0,0,0,139.78,2.53h0c1.37-.07,2.75-.17,4.14-.27a831.75,831.75,0,0,0,136.72-21.16c2.91-.71,5.78-1.38,8.64-2,149.63-34.62,287.57,83.37,253.09,216.72-12.76,49.31-9.52,101.89,33,144.41C1191.47,640.4,1183,708.76,1165.61,751.83Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<ellipse cx="919.83" cy="372.94" rx="14.05" ry="10.73" transform="translate(123.26 892.65) rotate(-60.79)" fill="#3f3d56" />
		<ellipse cx="928.21" cy="357.95" rx="14.05" ry="10.73" transform="translate(140.63 892.29) rotate(-60.79)" fill="#3f3d56" />
		<ellipse cx="936.59" cy="342.97" rx="14.05" ry="10.73" transform="translate(158 891.93) rotate(-60.79)" fill="#3f3d56" />
		<ellipse cx="944.97" cy="327.98" rx="14.05" ry="10.73" transform="translate(175.37 891.56) rotate(-60.79)" fill="#3f3d56" />
		<ellipse cx="953.34" cy="312.99" rx="14.05" ry="10.73" transform="translate(192.74 891.2) rotate(-60.79)" fill="#3f3d56" />
		<ellipse cx="961.72" cy="298" rx="14.05" ry="10.73" transform="translate(210.12 890.84) rotate(-60.79)" fill="#3f3d56" />
		<ellipse cx="970.1" cy="283.02" rx="14.05" ry="10.73" transform="translate(227.49 890.48) rotate(-60.79)" fill="#3f3d56" />
		<path
			d="M1062.5,200.06a52.4,52.4,0,0,0,6.36-3.18l-22.35-17.81L1073,194.15a51.51,51.51,0,0,0,20.73-35.07l-46.07-1.44,46.47-5.79A51.41,51.41,0,1,0,992,160.63a51.66,51.66,0,0,0-9.69,5.32L1005,200.41l-27.67-30.48A51.42,51.42,0,0,0,961,216.11a51.4,51.4,0,1,0,70.54,39.43,51.39,51.39,0,0,0,31-55.48Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<path
			d="M966.86,183A51.27,51.27,0,0,0,961,216.11a51.4,51.4,0,1,0,70.54,39.43C1041.77,251.27,969.68,178,966.86,183Z"
			transform="translate(-22.2 -101.19)"
			opacity="0.1"
		/>
		<path d="M1011.39,631.77h12.11a25,25,0,0,1,25,25v37.11a0,0,0,0,1,0,0H986.39a0,0,0,0,1,0,0V656.77A25,25,0,0,1,1011.39,631.77Z" fill="#3f3d56" />
		<path d="M1011.39,631.77h12.11a25,25,0,0,1,25,25v37.11a0,0,0,0,1,0,0H986.39a0,0,0,0,1,0,0V656.77A25,25,0,0,1,1011.39,631.77Z" opacity="0.1" />
		<circle cx="1014.97" cy="640.47" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="634.26" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="628.04" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="621.83" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="615.62" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="609.41" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="603.2" r="4.97" fill="#3f3d56" />
		<circle cx="1022.42" cy="605.68" r="4.97" fill="#3f3d56" />
		<circle cx="1027.39" cy="603.2" r="4.97" fill="#3f3d56" />
		<circle cx="1032.36" cy="600.71" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="596.99" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="590.78" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="584.56" r="4.97" fill="#3f3d56" />
		<circle cx="1014.97" cy="578.35" r="4.97" fill="#3f3d56" />
		<path
			d="M1057.77,663.52q-1.85.27-3.72.39a28.56,28.56,0,0,0,3.2-3.28,21,21,0,0,0-3-5.83,10.34,10.34,0,0,1-4,1.27c-1.23.18-2.47.31-3.71.39a66.41,66.41,0,0,0,4.75-5,21.1,21.1,0,1,0,6.93,15.61,20.68,20.68,0,0,0-.32-3.63Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<path
			d="M1074.37,688.21a9.78,9.78,0,0,1-4.18,1.4c-1.23.18-2.47.31-3.71.39a36.4,36.4,0,0,0,3.68-3.82,12.83,12.83,0,0,0-3.18-.42,12.44,12.44,0,1,0,7.39,2.45Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<circle cx="1007.51" cy="605.68" r="4.97" fill="#3f3d56" />
		<circle cx="1002.54" cy="603.2" r="4.97" fill="#3f3d56" />
		<circle cx="997.58" cy="600.71" r="4.97" fill="#3f3d56" />
		<path
			d="M1000,688.21a9.78,9.78,0,0,0,4.18,1.4c1.23.18,2.47.31,3.71.39a36.4,36.4,0,0,1-3.68-3.82,12.83,12.83,0,0,1,3.18-.42,12.44,12.44,0,1,1-7.39,2.45Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<path
			d="M1011.39,635.5h12.11a25,25,0,0,1,25,25v32.87a4.24,4.24,0,0,1-4.24,4.24H990.63a4.24,4.24,0,0,1-4.24-4.24V660.5A25,25,0,0,1,1011.39,635.5Z"
			fill="#3f3d56"
		/>
		<g opacity="0.1">
			<path
				d="M1058.15,664.88a16.19,16.19,0,0,1-1.61,1.52c.57,0,1.14-.09,1.7-.15C1058.22,665.79,1058.2,665.33,1058.15,664.88Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M1049.09,658.94c1.24-.08,2.48-.21,3.71-.39a11.64,11.64,0,0,0,3.23-.88,22.6,22.6,0,0,0-1.74-2.87,7.52,7.52,0,0,1-2,.85A29.59,29.59,0,0,1,1049.09,658.94Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M1018.53,669.61a21.08,21.08,0,0,1,32.5-17.76l.32-.34a21.1,21.1,0,1,0-27.82,31.73A21,21,0,0,1,1018.53,669.61Z"
				transform="translate(-22.2 -101.19)"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M1066.48,692.49c1.24-.09,2.48-.22,3.71-.4a9.77,9.77,0,0,0,4.18-1.39,12.39,12.39,0,0,1,5,8.8,11.55,11.55,0,0,0,.07-1.32,12.39,12.39,0,0,0-5-10,9.78,9.78,0,0,1-4.18,1.4l-1,.12A24.61,24.61,0,0,1,1066.48,692.49Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M1067,688.24c.42,0,.83,0,1.24.09.65-.69,1.29-1.43,1.94-2.15a12.83,12.83,0,0,0-3.18-.42,12.42,12.42,0,0,0-12.42,12.42c0,.42,0,.84.06,1.24A12.43,12.43,0,0,1,1067,688.24Z"
				transform="translate(-22.2 -101.19)"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M1007.85,692.49c-1.24-.09-2.48-.22-3.71-.4a9.77,9.77,0,0,1-4.18-1.39,12.39,12.39,0,0,0-5,8.8,11.55,11.55,0,0,1-.07-1.32,12.39,12.39,0,0,1,5-10,9.78,9.78,0,0,0,4.18,1.4l1,.12A25.5,25.5,0,0,0,1007.85,692.49Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M1007.35,688.24c-.42,0-.83,0-1.24.09-.65-.69-1.29-1.43-1.94-2.15a12.83,12.83,0,0,1,3.18-.42,12.42,12.42,0,0,1,12.42,12.42c0,.42,0,.84-.06,1.24A12.43,12.43,0,0,0,1007.35,688.24Z"
				transform="translate(-22.2 -101.19)"
			/>
		</g>
		<path d="M57,539h95.54a25,25,0,0,1,25,25V684.49a0,0,0,0,1,0,0H32a0,0,0,0,1,0,0V564a25,25,0,0,1,25-25Z" fill="#3f3d56" />
		<path d="M57,539h95.54a25,25,0,0,1,25,25V684.49a0,0,0,0,1,0,0H32a0,0,0,0,1,0,0V564a25,25,0,0,1,25-25Z" opacity="0.1" />
		<circle cx="98.97" cy="559.33" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="544.78" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="530.22" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="515.67" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="501.11" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="486.56" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="472.01" r="11.64" fill="#3f3d56" />
		<circle cx="116.43" cy="477.83" r="11.64" fill="#3f3d56" />
		<circle cx="128.07" cy="472.01" r="11.64" fill="#3f3d56" />
		<circle cx="139.72" cy="466.18" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="457.45" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="442.9" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="428.34" r="11.64" fill="#3f3d56" />
		<circle cx="98.97" cy="413.79" r="11.64" fill="#3f3d56" />
		<path
			d="M169.44,477.43q-4.33.63-8.7.92a67.77,67.77,0,0,0,7.49-7.69A49.35,49.35,0,0,0,161.29,457a23.74,23.74,0,0,1-9.32,3c-2.88.42-5.79.73-8.7.93,3.83-3.1,7.38-7.79,11.13-11.6a49.59,49.59,0,1,0,15.49,28.06Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<path
			d="M208.34,535.29c-2.74,1.93-6.38,2.76-9.79,3.26-2.89.43-5.79.73-8.7.93,3-2.41,5.76-5.77,8.62-8.94a29.13,29.13,0,1,0,9.87,4.75Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<circle cx="81.5" cy="477.83" r="11.64" fill="#3f3d56" />
		<circle cx="69.86" cy="472.01" r="11.64" fill="#3f3d56" />
		<circle cx="58.22" cy="466.18" r="11.64" fill="#3f3d56" />
		<path
			d="M34,535.29c2.74,1.93,6.38,2.76,9.8,3.26,2.88.43,5.78.73,8.69.93-3-2.41-5.76-5.77-8.62-8.94A29.13,29.13,0,1,1,34,535.29Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<path d="M57,547.69h95.54a25,25,0,0,1,25,25V689a4.24,4.24,0,0,1-4.24,4.24H36.26A4.24,4.24,0,0,1,32,689V572.69a25,25,0,0,1,25-25Z" fill="#3f3d56" />
		<g opacity="0.1">
			<path
				d="M170.32,480.61a37.62,37.62,0,0,1-3.76,3.56c1.33-.09,2.67-.21,4-.34C170.51,482.75,170.44,481.67,170.32,480.61Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M149.1,466.71c2.91-.2,5.81-.5,8.69-.93a27.71,27.71,0,0,0,7.58-2,50.55,50.55,0,0,0-4.08-6.74,17.18,17.18,0,0,1-4.66,2A69.21,69.21,0,0,1,149.1,466.71Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M77.5,491.7a49.39,49.39,0,0,1,76.15-41.61c.25-.26.5-.55.75-.8a49.44,49.44,0,1,0-65.17,74.35A49.21,49.21,0,0,1,77.5,491.7Z"
				transform="translate(-22.2 -101.19)"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M189.85,545.3q4.36-.28,8.7-.93c3.41-.49,7-1.32,9.79-3.26A29.06,29.06,0,0,1,220,561.72c.1-1,.16-2,.16-3.07a29,29,0,0,0-11.79-23.36c-2.74,1.93-6.38,2.76-9.79,3.26-.77.11-1.53.2-2.29.29A57.62,57.62,0,0,1,189.85,545.3Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M191,535.36a28.06,28.06,0,0,1,2.92.2c1.52-1.62,3-3.34,4.53-5a29,29,0,0,0-36.55,28.11c0,1,.05,2,.14,2.91A29.11,29.11,0,0,1,191,535.36Z"
				transform="translate(-22.2 -101.19)"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M52.48,545.3q-4.36-.28-8.69-.93c-3.42-.49-7.06-1.32-9.8-3.26a29.06,29.06,0,0,0-11.63,20.61c-.1-1-.16-2-.16-3.07A29,29,0,0,1,34,535.29c2.74,1.93,6.38,2.76,9.8,3.26.76.11,1.52.2,2.28.29A58.48,58.48,0,0,0,52.48,545.3Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M51.31,535.36a28.06,28.06,0,0,0-2.92.2c-1.52-1.62-3-3.34-4.53-5a29,29,0,0,1,36.55,28.11c0,1,0,2-.14,2.91A29.11,29.11,0,0,0,51.31,535.36Z"
				transform="translate(-22.2 -101.19)"
			/>
		</g>
		<polygon points="679.98 696.14 484.98 693.64 485.55 688.64 494.98 606.14 666.23 606.14 678.83 688.64 679.79 694.89 679.98 696.14" fill="#d0d2d5" />
		<polygon points="679.79 694.89 582.48 694.89 484.98 693.64 485.55 688.64 678.83 688.64 679.79 694.89" opacity="0.1" />
		<rect x="452.48" y="689.89" width="258.75" height="6.25" fill="#d0d2d5" />
		<path d="M980.3,211.81a16.28,16.28,0,0,0-16.19-16.36H241.5a16.28,16.28,0,0,0-16.2,16.36v438h755Z" transform="translate(-22.2 -101.19)" fill="#444053" />
		<path
			d="M225.3,645.45v51.31A16.19,16.19,0,0,0,241.5,713H964.11a16.19,16.19,0,0,0,16.19-16.19V645.45Z"
			transform="translate(-22.2 -101.19)"
			fill="#d0d2d5"
		/>
		<rect x="234.35" y="121.76" width="696.25" height="393.75" class="w-primary" opacity="0.1" />
		<path
			d="M606.55,693.49a16.89,16.89,0,0,0,13.28-6.44v0a18.18,18.18,0,0,0,1.31-1.93l-9.26-1.52,10,.07a16.83,16.83,0,0,0,.32-13.37l-13.43,7,12.39-9.1A16.87,16.87,0,1,0,593.29,687v0A16.87,16.87,0,0,0,606.55,693.49Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<polygon points="601.23 611.76 679.02 689.89 667.09 611.76 601.23 611.76" opacity="0.1" />
		<rect x="253.73" y="175.38" width="537.5" height="285.41" fill="#fff" />
		<rect x="283.71" y="249.78" width="149.92" height="7.77" class="w-primary" opacity="0.1" />
		<rect x="297.04" y="208.69" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="318.14" y="208.69" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="339.24" y="208.69" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="360.34" y="208.69" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="381.44" y="208.69" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="402.54" y="208.69" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="297.04" y="216.47" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="318.14" y="216.47" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="339.24" y="216.47" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="360.34" y="216.47" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="381.44" y="216.47" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="402.54" y="216.47" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="297.04" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="318.14" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="339.24" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="360.34" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="381.44" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="402.54" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="612.43" y="269.77" width="149.92" height="7.77" class="w-primary" opacity="0.1" />
		<rect x="625.76" y="228.68" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="646.86" y="228.68" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="667.96" y="228.68" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="689.06" y="228.68" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="710.16" y="228.68" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="731.26" y="228.68" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="625.76" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="646.86" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="667.96" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="689.06" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="710.16" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="731.26" y="236.46" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="625.76" y="256.45" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="646.86" y="256.45" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="667.96" y="256.45" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="689.06" y="256.45" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="710.16" y="256.45" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="731.26" y="256.45" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="612.43" y="326.41" width="149.92" height="7.77" class="w-primary" opacity="0.1" />
		<rect x="625.76" y="285.32" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="646.86" y="285.32" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="667.96" y="285.32" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="689.06" y="285.32" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="710.16" y="285.32" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="731.26" y="285.32" width="14.44" height="41.09" class="w-primary" opacity="0.1" />
		<rect x="625.76" y="293.09" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="646.86" y="293.09" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="667.96" y="293.09" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="689.06" y="293.09" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="710.16" y="293.09" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="731.26" y="293.09" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="625.76" y="313.08" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="646.86" y="313.08" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="667.96" y="313.08" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="689.06" y="313.08" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="710.16" y="313.08" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<rect x="731.26" y="313.08" width="14.44" height="4.44" class="w-primary" opacity="0.1" />
		<path
			d="M397.86,438.85a39.57,39.57,0,0,1-3.06-4.5l21.59-3.54L393,431a39.43,39.43,0,0,1-.75-31.18l31.32,16.25-28.88-21.23a39.35,39.35,0,1,1,65,44A39.33,39.33,0,0,1,464.2,446l-28,14.55,29.87-10a39.33,39.33,0,0,1-6.34,36.94,39.35,39.35,0,1,1-61.85,0,39.35,39.35,0,0,1,0-48.65Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<path
			d="M468.13,463.18a39.18,39.18,0,0,1-8.42,24.32,39.35,39.35,0,1,1-61.85,0C392.59,480.81,468.13,458.76,468.13,463.18Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<path
			d="M435.1,561.65c.07.11.12.22.19.33H421.93c.07-.11.12-.22.19-.33a13,13,0,0,1-1.72-6.58,13.55,13.55,0,0,1,.81-4.63s0,0,0,0c.74.12,1.66.25,2.72.36a46.86,46.86,0,0,0,9.32,0c.88-.09,1.81-.22,2.75-.36a.64.64,0,0,1,0,.07,13.34,13.34,0,0,1,.79,4.56A13,13,0,0,1,435.1,561.65Z"
			transform="translate(-22.2 -101.19)"
			fill="#3f3d56"
			opacity="0.2"
		/>
		<path
			d="M387.09,562h-74.3c-.17-.43-.32-.88-.45-1.32a39.11,39.11,0,0,1,.87-25.72,39.34,39.34,0,0,1-14.36-46.49,40.69,40.69,0,0,1-4.26-3.4l19.58-9.76-22.25,7.06A39.39,39.39,0,0,1,282,452.79l34.72,6.27L282.86,447.3a39.35,39.35,0,1,1,75.08,22.89,40.09,40.09,0,0,1,6.41,5.53L341.87,497.9l25.59-18.4a39.36,39.36,0,0,1,4.84,37.18A39.36,39.36,0,0,1,387.09,562Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<path
			d="M387.09,562h-74.3c-.17-.43-.32-.88-.45-1.32a39.11,39.11,0,0,1,.87-25.72c-7-4.85,58.66-48.21,60-44a39.27,39.27,0,0,1-.87,25.73A39.36,39.36,0,0,1,387.09,562Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<path
			d="M723.54,508.69a39.16,39.16,0,0,1-19.7,16.56,40.6,40.6,0,0,1,.17,8.47l-31.52-1.89,30.78,6.73A39.44,39.44,0,0,1,688.08,562h-42.9v1.11H601.51c.09-.38.18-.75.28-1.11a39.49,39.49,0,0,1,24-26.71,39.38,39.38,0,0,1,25-41.76,39.39,39.39,0,0,1-.33-5.43l20.35,8-20.12-11.82A39.44,39.44,0,0,1,666,457.14l18.54,30-13.89-33a39.34,39.34,0,0,1,52.88,54.58Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<path
			d="M698.57,550.45A38.79,38.79,0,0,1,688.08,562h-42.9v1.11H601.51c.09-.38.18-.75.28-1.11a39.49,39.49,0,0,1,24-26.71c-.37-2.87,8.11-2.48,19.4-.58C667.26,538.41,700.07,547.94,698.57,550.45Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<path
			d="M512.65,445.46S518,474.63,506,484.14s10.14,66.58,46.92,59.93,51.68-54.54,51.68-54.54-36.78-7.6-23.15-46.28Z"
			transform="translate(-22.2 -101.19)"
			fill="#efb7b9"
		/>
		<path
			d="M512.65,445.46S518,474.63,506,484.14s10.14,66.58,46.92,59.93,51.68-54.54,51.68-54.54-36.78-7.6-23.15-46.28Z"
			transform="translate(-22.2 -101.19)"
			opacity="0.1"
		/>
		<circle cx="522.95" cy="304.8" r="60.87" fill="#efb7b9" />
		<path
			d="M645.18,521.71v41.38h-201V523.6a25.51,25.51,0,0,1,15.56-23.48l5.77-2.45c14.58-6.21,32.29-13.92,38.08-17.13a9.53,9.53,0,0,0,1.78-1.15c3.17-3.17,2.54.95,2.54.95s-.09.78-.15,2.14c-.34,9.31,1,45.93,43.9,42.88,41.86-3,43.7-31.24,43.32-39.55a20.67,20.67,0,0,0-.2-2.3c0-.22,3.6,1,8.48,2.76,8.77,3.23,21.66,8.43,25.13,10.88,1.08.75,2.39,1.55,3.81,2.34a25.36,25.36,0,0,1,13,22.22Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<path
			d="M603.25,486.27c-3.54,16.42-14.66,47.18-54.77,48-42.71.84-45.17-41.64-44.91-53.69a9.25,9.25,0,0,0,1.79-1.16c3.17-3.17,2.54.95,2.54.95s-5.39,48.51,43.75,45,43.12-41.85,43.12-41.85C594.77,483.29,598.37,484.47,603.25,486.27Z"
			transform="translate(-22.2 -101.19)"
			opacity="0.1"
		/>
		<path
			d="M473.1,409c4-1.3,8.77.9,11,4s2.54,6.85,2.81,10.44c2.65.56,4.14-2.44,4.38-4.69,1-9.21,1.05-18.59,4.14-27.47s9.84-17.48,20.22-20.9a23.36,23.36,0,0,1,11-1.05c2.19.37,4.26,1.13,6.45,1.49,7.57,1.25,15.82-2.34,22.93.16,4.45,1.57,7.32,5.2,9.36,8.84a19.29,19.29,0,0,0,3.64,5.29c3,2.7,7.76,3.41,12.08,4.26s9.06,2.31,10.91,5.65c1.67,3,.44,6.5-.12,9.78a23.88,23.88,0,0,0,2.47,15.1c.8,1.52,2.42,3.24,4.33,2.75a3.82,3.82,0,0,0,2.07-1.68c5-7,4.84-16.08,11.07-22.33,2.09-2.09,4.78-3.73,6.81-5.86a13.18,13.18,0,0,0,3.53-12c-1.12-4.81-5-8.82-8.07-13a51,51,0,0,1-8.65-17.95,3.2,3.2,0,0,1,0-2.34,6.27,6.27,0,0,1,2.21-1.81A8.36,8.36,0,0,0,610.54,335c-1.74-3.63-5.65-6.32-9.94-7.94s-9-2.31-13.66-3c-3.06.45-6.65-1.3-7.6-3.75-.42-1.09-.42-2.27-.85-3.36-.83-2.09-3.22-3.57-5.79-4.08a17,17,0,0,0-7.82.55c-2.88.81-6.22,2-8.73.62-1.32-.75-2.06-2.11-3.48-2.72-2.88-1.23-5.92,1.25-8,3.3a19.7,19.7,0,0,1-7.23,4.85c-2.89,1-6.45,1-8.94-.59-2-1.27-3-3.31-4.59-4.93-2.61-2.67-6.74-4.12-10.86-4.45a38.38,38.38,0,0,0-12.26,1.34,74.24,74.24,0,0,0-21.55,9c-3.33,2.09-6.52,4.53-8.26,7.68a17.43,17.43,0,0,0-1.86,7.92c-.31,8.61,2.55,17.69,10,23.72-4.54,1.9-6.08,6.55-7.14,10.68-1.7,6.58-3.41,13.28-2.77,20,.32,3.47,1.27,6.94.76,10.39-.24,1.67-.82,3.31-.68,5A4.78,4.78,0,0,0,473.1,409Z"
			transform="translate(-22.2 -101.19)"
			fill="#454b69"
		/>
		<g opacity="0.1">
			<path
				d="M621.3,378.18a14.34,14.34,0,0,1-2.63,3.87c-2,2.13-4.72,3.77-6.81,5.87-6.23,6.24-6.1,15.35-11.07,22.33a3.8,3.8,0,0,1-2.07,1.67c-1.91.49-3.53-1.23-4.33-2.75a24.3,24.3,0,0,1-2.17-5.85q-.16.76-.3,1.53a23.88,23.88,0,0,0,2.47,15.1c.8,1.52,2.42,3.24,4.33,2.75a3.82,3.82,0,0,0,2.07-1.68c5-7,4.84-16.08,11.07-22.33,2.09-2.09,4.78-3.73,6.81-5.86a13.18,13.18,0,0,0,3.53-12A14.42,14.42,0,0,0,621.3,378.18Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M607.71,335a6.14,6.14,0,0,0-2.21,1.8,3.2,3.2,0,0,0,0,2.34,48.89,48.89,0,0,0,2.21,6.65l0,0A8.36,8.36,0,0,0,610.54,335a10.94,10.94,0,0,0-1-1.7A8.34,8.34,0,0,1,607.71,335Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M479.11,348.48a27.15,27.15,0,0,1-9.55-16.9,24,24,0,0,0-.44,4,30.43,30.43,0,0,0,4.8,18A10.23,10.23,0,0,1,479.11,348.48Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M591.92,394.07c.56-3.28,1.79-6.77.12-9.78-1.85-3.34-6.58-4.81-10.91-5.65s-9.06-1.56-12.08-4.26a19.29,19.29,0,0,1-3.64-5.29c-2-3.64-4.91-7.27-9.36-8.84-7.11-2.5-15.36,1.09-22.93-.16-2.19-.36-4.26-1.12-6.45-1.49a23.36,23.36,0,0,0-11,1c-10.38,3.42-17.13,12-20.22,20.9s-3.16,18.26-4.14,27.47c-.24,2.25-1.73,5.25-4.38,4.69-.27-3.59-.6-7.34-2.81-10.44s-6.93-5.31-11-4a5.3,5.3,0,0,1-3.08-2,16.81,16.81,0,0,1-.06,4c-.24,1.67-.82,3.31-.68,5A4.78,4.78,0,0,0,473.1,409c4-1.3,8.77.9,11,4s2.54,6.85,2.81,10.44c2.65.56,4.14-2.44,4.38-4.69,1-9.21,1.05-18.59,4.14-27.47s9.84-17.48,20.22-20.9a23.36,23.36,0,0,1,11-1.05c2.19.37,4.26,1.13,6.45,1.49,7.57,1.25,15.82-2.34,22.93.16,4.45,1.57,7.32,5.2,9.36,8.84a19.29,19.29,0,0,0,3.64,5.29c3,2.7,7.76,3.41,12.08,4.26s8.71,2.21,10.69,5.31C591.85,394.51,591.88,394.29,591.92,394.07Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M470,389.53a28.13,28.13,0,0,0-.48-8.12,36.2,36.2,0,0,0-.28,8.51c.06.73.16,1.45.26,2.18C469.62,391.24,469.84,390.39,470,389.53Z"
				transform="translate(-22.2 -101.19)"
			/>
		</g>
		<rect x="818.73" y="398.69" width="90" height="62.45" fill="#fff" />
		<path
			d="M922.91,547a11.11,11.11,0,0,1-5.61,4.71,11.43,11.43,0,0,1,.05,2.41l-9-.54,8.76,1.92a11.27,11.27,0,0,1-4.32,6.66H900.61v.32H888.19c0-.11,0-.21.08-.32a11,11,0,0,1,1.22-2.89,11.14,11.14,0,0,1,5.6-4.71,11.19,11.19,0,0,1,7.11-11.88,10,10,0,0,1-.09-1.54l5.79,2.28-5.73-3.36a11.18,11.18,0,0,1,4.37-7.73l5.27,8.54-3.95-9.4a11.2,11.2,0,0,1,15,15.53Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<path
			d="M915.8,558.91a11,11,0,0,1-3,3.28H900.61v.32H888.19c0-.11,0-.21.08-.32a11,11,0,0,1,1.22-2.89,11.14,11.14,0,0,1,5.6-4.71c-.1-.81,2.31-.7,5.52-.16C906.89,555.49,916.23,558.2,915.8,558.91Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<path
			d="M844.35,547a11.11,11.11,0,0,0,5.61,4.71,11.43,11.43,0,0,0-.05,2.41l9-.54-8.76,1.92a11.27,11.27,0,0,0,4.32,6.66h12.21v.32h12.43c0-.11-.06-.21-.08-.32a11.71,11.71,0,0,0-1.22-2.89,11.2,11.2,0,0,0-5.61-4.71,11.19,11.19,0,0,0-7.11-11.88,11.38,11.38,0,0,0,.1-1.54l-5.79,2.28,5.72-3.36a11.18,11.18,0,0,0-4.37-7.73l-5.27,8.54,3.95-9.4a11.2,11.2,0,0,0-15,15.53Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<path
			d="M851.46,558.91a11,11,0,0,0,3,3.28h12.21v.32h12.43c0-.11-.06-.21-.08-.32a11.71,11.71,0,0,0-1.22-2.89,11.2,11.2,0,0,0-5.61-4.71c.1-.81-2.31-.7-5.52-.16C860.37,555.49,851,558.2,851.46,558.91Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
			opacity="0.1"
		/>
		<rect x="852.51" y="412.45" width="20.11" height="22.4" fill="#464353" />
		<rect x="852.51" y="412.45" width="20.11" height="22.4" opacity="0.1" />
		<polygon points="843.94 455.35 843.53 461.17 849.27 461.17 849.63 453.48 843.94 455.35" fill="#a1616a" />
		<polygon points="874.18 455.35 873.31 461.17 878.57 461.17 878.9 453.48 874.18 455.35" fill="#a1616a" />
		<path
			d="M879.73,524.85s-.2,2.91-.9,3.67,6.25,7.49,11.72.84c0,0-2.35-2-1.8-4.24S879.73,524.85,879.73,524.85Z"
			transform="translate(-22.2 -101.19)"
			fill="#a1616a"
		/>
		<path
			d="M879.73,524.85s-.2,2.91-.9,3.67,6.25,7.49,11.72.84c0,0-2.35-2-1.8-4.24S879.73,524.85,879.73,524.85Z"
			transform="translate(-22.2 -101.19)"
			opacity="0.1"
		/>
		<circle cx="862.56" cy="416.2" r="10.06" fill="#a1616a" />
		<path
			d="M884.24,531.09s-4.22-.07-4.85-3.92a25.72,25.72,0,0,1-2.64,2c-.41.14-4.44,2.42-5.2,2.56a4.29,4.29,0,0,0-2,1,8.73,8.73,0,0,0-3.33,6.52c-.14,4.51-.69,13.32-.69,13.32l.21,3.19v2.08s3.74,1,6-1c0,0,0-.76.42-1s.48-1.25.41-1.67a16.22,16.22,0,0,1,0-1.87,1.34,1.34,0,0,0,.56.83,4.27,4.27,0,0,1,.14,1.74c-.21.41-.42,1.38-.21,1.59a1,1,0,0,1-.14,1.18,6.88,6.88,0,0,0-.9,1.74s-.21,1.24-.42,1.38a5.77,5.77,0,0,0-.48,1.6h25a24.06,24.06,0,0,1-1-3.89c.07-.62-.62-3.19-.14-3.88a5.32,5.32,0,0,0,1.11-3.19l.28,2.7-.76,6s4.64,1.46,5.13.55a32.07,32.07,0,0,0,1.18-4.85s1.11-5,1.39-5.69.9-14.56-2.85-16.57-10.82-5.47-11-5.3A4.65,4.65,0,0,1,884.24,531.09Z"
			transform="translate(-22.2 -101.19)"
			class="w-primary"
		/>
		<path
			d="M872.19,544.65a5,5,0,0,0-.86,3.51,9.71,9.71,0,0,0,1.18,3.48"
			transform="translate(-22.2 -101.19)"
			fill="none"
			stroke="#000"
			stroke-miterlimit="10"
			opacity="0.1"
		/>
		<path
			d="M896.48,545.6a10.57,10.57,0,0,1,.42,1.56,4.75,4.75,0,0,1-.66,3.92"
			transform="translate(-22.2 -101.19)"
			fill="none"
			stroke="#000"
			stroke-miterlimit="10"
			opacity="0.1"
		/>
		<path
			d="M880.26,510.51a3.33,3.33,0,0,1,2.35.24,12.3,12.3,0,0,0,2.24.89c.52.11,1,.11,1.58.19a4.92,4.92,0,0,1,3.94,3.85c.45,2.46-1,4.88-1,7.39A6.18,6.18,0,0,0,890.8,527a11.6,11.6,0,0,1,2,2.39c.29.64.35,1.37.63,2a5.16,5.16,0,0,0,2.84,2.42,19.36,19.36,0,0,1,3.47,1.56c2,1.33,3.18,3.88,5.5,4.43a.92.92,0,0,0,.73,0,.9.9,0,0,0,.34-.7,3.27,3.27,0,0,0-.93-2.69c-.57-.57-1.4-1-1.58-1.77s.3-1.43.56-2.13c.83-2.27-.55-4.74-2.12-6.58s-3.45-3.66-3.83-6a15.79,15.79,0,0,1-.09-2.38,15.79,15.79,0,0,0-.84-4.81,7.07,7.07,0,0,0-1.2-2.31c-.5-.6-1.14-1.06-1.66-1.64a24.7,24.7,0,0,1-1.86-3,9,9,0,0,0-7.35-4.13c-2.81,0-5.47,1.24-7.85,2.73a6.73,6.73,0,0,0-2.22,1.95c-1,1.59-.7,3.69-1.28,5.49s-1.95,3.13-2.43,4.89a3.43,3.43,0,0,0-.07,1.59,8.14,8.14,0,0,0,.71,1.6,11.91,11.91,0,0,1,.89,4.05c.15,1.68.22,3.49-.66,4.92A8.24,8.24,0,0,1,870,531a4.87,4.87,0,0,0,3.49,1.07c1.9.08,4.1,0,5.28-1.5a5.24,5.24,0,0,0,.8-3.65,15.35,15.35,0,0,0-.84-4.08c-.65-1.85-1.65-3.6-1.94-5.54a5.22,5.22,0,0,1,1-4c.36-.45,1-.68,1.3-1.16C879.47,511.5,879.36,510.83,880.26,510.51Z"
			transform="translate(-22.2 -101.19)"
			fill="#464353"
		/>
		<g opacity="0.1">
			<path
				d="M872.27,519.78a11.91,11.91,0,0,1,.89,4.05c0,.44.07.89.08,1.34a13.76,13.76,0,0,0,0-2.94,11.81,11.81,0,0,0-.89-4,9,9,0,0,1-.71-1.59,3.38,3.38,0,0,0-.07,1.58A8.14,8.14,0,0,0,872.27,519.78Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M878.86,529c-1.18,1.49-3.38,1.58-5.28,1.51a7.82,7.82,0,0,1-2.37-.36c-.38.31-.79.6-1.19.88a4.87,4.87,0,0,0,3.49,1.07c1.9.08,4.1,0,5.28-1.5a5,5,0,0,0,.82-3.41A3.83,3.83,0,0,1,878.86,529Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M903.87,533a1.61,1.61,0,0,0,.12.33c.12-.28.26-.57.37-.86a4.14,4.14,0,0,0,.2-2.06,4.12,4.12,0,0,1-.13.47C904.17,531.58,903.7,532.28,903.87,533Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M890.43,516.16a6.25,6.25,0,0,0,0-2.07,4.91,4.91,0,0,0-3.94-3.85c-.53-.09-1.06-.09-1.58-.19a13.06,13.06,0,0,1-2.24-.89,3.33,3.33,0,0,0-2.35-.25c-.9.32-.79,1-1.19,1.66-.29.48-.94.72-1.3,1.16a5.17,5.17,0,0,0-1,3.69,4.51,4.51,0,0,1,.93-2.09c.36-.45,1-.68,1.3-1.16.4-.67.29-1.34,1.19-1.66a3.33,3.33,0,0,1,2.35.24,12.3,12.3,0,0,0,2.24.89c.52.11,1,.11,1.58.19a4.92,4.92,0,0,1,3.94,3.85A3.93,3.93,0,0,1,890.43,516.16Z"
				transform="translate(-22.2 -101.19)"
			/>
			<path
				d="M906,538.17a1,1,0,0,1-.73,0c-2.32-.55-3.52-3.1-5.5-4.43a20.87,20.87,0,0,0-3.47-1.56,5.14,5.14,0,0,1-2.84-2.41c-.28-.65-.34-1.37-.63-2a11.65,11.65,0,0,0-2-2.39,6.23,6.23,0,0,1-1.44-3.51,7.68,7.68,0,0,0-.09,1.17A6.18,6.18,0,0,0,890.8,527a11.6,11.6,0,0,1,2,2.39c.29.64.35,1.37.63,2a5.16,5.16,0,0,0,2.84,2.42,19.36,19.36,0,0,1,3.47,1.56c2,1.33,3.18,3.88,5.5,4.43a.92.92,0,0,0,.73,0,.91.91,0,0,0,.34-.7,3.3,3.3,0,0,0-.06-1.13A.61.61,0,0,1,906,538.17Z"
				transform="translate(-22.2 -101.19)"
			/>
		</g>
		<rect x="827.91" y="403.28" width="12.86" height="18.37" class="w-primary" opacity="0.1" />
		<rect x="829.75" y="405.91" width="9.18" height="13.12" class="w-primary" opacity="0.1" />
		<rect x="883.01" y="406.04" width="21.12" height="11.02" class="w-primary" opacity="0.1" />
		<rect x="885.77" y="407.48" width="15.61" height="8.15" class="w-primary" opacity="0.1" />
	</svg>
</template>
<script>
export default {
	name: 'VideoConferenceHomeImage'
}
</script>

<style scoped>
.w-primary {
	fill: var(--v-primary-base);
}
</style>
